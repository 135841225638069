import React from "react"

import "./value.scss"

import arrow from "../../assets/icons/arrow.svg"


const Value = () => (
  <div className="value">
    <div className="value__bg"></div>
    <div className="value__title">
      <p className="value__title__label label">
        co-creation
      </p>
      <h1>
        Everybody wins when we co-create together
      </h1>
    </div>

    <div className="value__graph">
        <h2 className="value__graph__side-headers">
            Value Customers
        </h2>
        
        <ul className="value__graph__values value__graph__values--users">
            <li>Customers need in focus</li>
            <li>Roche team helps them</li>
            <li>New supportive solutions</li>
            <li>Shared ambition for creating better lives</li>
            <li>Real world data & evidence</li>
        </ul>

        <div className="value__graph__center">
            <h2>New solutions beyond-the-pill</h2>
            <p>that equip patients and HCPs to understand the disease better and take the right actions faster</p>
        </div>

        <ul className="value__graph__values value__graph__values--roche">
            <li>Interactions & insights</li>
            <li>Trust</li>
            <li>New digital health solutions to scale</li>
            <li>Shared ambition for creating better lives</li>
            <li>Real world data & evidence</li>
        </ul>

        <h2 className="value__graph__side-headers">
            Value Roche
        </h2>
    </div>

    <img src={arrow} alt="arrow" className="bottom-arrow"/>

  </div>
)

export default Value