import React from 'react';
import App from '../components/app';


const IndexPage = () => {
  return (
    <App />
  )
}


export default IndexPage