import React from "react"

import "./open-house.scss"

const OpenHouse = () => (
  <div className="open-house">
    <div className="open-house__bg"></div>
    <div className="open-house__title">
      <p className="open-house__title__label label">
        Quartly open house
      </p>
      <h1>
        The first Friday in each quarter we host a 90 min knowledge sharing session where we cover
      </h1>
    </div>

    <div className="open-house__content">
      <ul className="open-house__content__we-cover">
        <li className="open-house__content__we-cover__item">Recent customer insights</li>
        <li className="open-house__content__we-cover__item">-</li>
        <li className="open-house__content__we-cover__item">New co-creation projects</li>
        <li className="open-house__content__we-cover__item">-</li>
        <li className="open-house__content__we-cover__item">Project progress</li>
      </ul>

      <a href="https://forms.gle/HjAbogg4JqwfXpzz5" className="btn open-house__content__btn" target="_blank" rel="noreferrer">Sign up</a>

    </div>
  </div>
)

export default OpenHouse