import React from "react"

import { Link } from "gatsby"

import "./header.scss"

import iLabLogo from "../../assets/logos/Logo-InnovationLab_White.svg"
import danishFlag from "../../assets/icons/danish-flag.svg"
import rocheLogo from "../../assets/logos/white-roche-logo.svg"

// function openMobileNav (e) {
//   const [burgerMenu] = document.getElementsByClassName('hamburger');
//   const [nav] = document.getElementsByClassName('navigation');
  
//   burgerMenu.classList.toggle('is-active');
//   nav.classList.toggle('is-active');
//   document.documentElement.style.overflow = 'hidden';
// }


const Header = () => (
  <header>

    <div className="ilab-logo">
      <Link to="//"><img src={iLabLogo} alt="iLab logo" className="ilab-logo__logo"/></Link>
      <img src={danishFlag} alt="Danish Flag" className="ilab-logo__danish-flag"/>
    </div>

    {/* <div className="hamburger" id="hamburger-1" onClick={openMobileNav}>
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </div> */}

    {/* <nav className="navigation">
      <ul>
        <li><Link to="/about/">About</Link></li>
        <li><Link to="/work/">Work</Link></li>
        <li><Link to="/join/">Join us</Link></li>
      </ul>

      <img src={rocheLogo} alt="roche logo" className="roche-logo-mobile"/>
    </nav> */}

    <div className="roche-logo">
      <img src={rocheLogo} alt="roche logo"/>
    </div>
  </header>
)

export default Header
