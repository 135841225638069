import React from 'react'

import "./hero.scss"

import scrollIcon from "../../assets/icons/scroll-icon.svg"

const Hero = (props) => {

  return (
    <div className="hero">
      <div className="hero__bg"></div>
      <div className="hero__title">
        <p className="hero__title__label label">
          Our vision
        </p>
        <h1 className="hero__title__welcome">
          Welcome to the InnovationLab universe, {props.visitorName}
        </h1>
        <h1 className="hero__title__vision">
          Deliver better outcomes for more patients faster by co-creating new innovative solutions
        </h1>
      </div>

      <div className="hero__scroll">
        <img src={scrollIcon} className="hero__scroll__icon" alt="scroll"/>
        <h5>Scroll to explore</h5>
      </div>

    </div>
  )
}

export default Hero