import React from "react"

import "./footer.scss"

import rocheLogo from "../../assets/logos/blue-roche-logo.svg"
import iLabLogo from "../../assets/logos/Logo-InnovationLab_Blue.svg"


const Footer = () => (
  <footer className="footer">
    {/* <div className="container">
      <div className="footer__content footer__content__info">
        <h3>I want to hear more</h3>
        <p>
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui 
          officia deserunt mollit anim id est laborum, occaecat cupidatat 
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
        </p>
      </div>

      <div className="footer__content footer__content__form">
        <h4>Fill out the form and participate in our next brainstorm</h4>
        <form>
          <label htmlFor="name">Your Name</label>
          <input type="text" id="name" className="text-inputs" name="name" />
          <label htmlFor="email">Your Email</label>
          <input type="text" id="email" className="text-inputs" name="email" />
          <input type="submit" value="Sign me up" className="btn btn--outline"/>
        </form> 
      </div>
    </div> */}

    <div className="footer__logo-panel">
      <img src={iLabLogo} className="footer__logo-panel__ilab-logo" alt="iLab logo"/>
      <img src={rocheLogo} className="footer__logo-panel__roche-logo" alt="Roche logo"/>
    </div>

  </footer>
)

export default Footer