import React from 'react';
import { useGoogleLogin } from 'react-use-googlelogin'

import Layout from "./layout/layout"
import Hero from "./hero/hero"
import Value from "./value/value"
import Balance from "./balance/balance"
import HowHero from "./how-hero/how-hero"
import CoCreationModel from "./co-creation-model/co-creation-model"
import Stakeholders from "./stakeholders/stakeholders"
import ProjectOverview from "./projects/projects"
import OpenHouse from "./open-house/open-house"

import "./sign-in/sign-in.scss"
import iLabLogo from "../assets/logos/Logo-InnovationLab_White.svg"


const App = () => {
  const { signIn, googleUser, isSignedIn } = useGoogleLogin({
    clientId: '105929741418-0e1p1sldo9b39esq2i1oegrp59g4tof0.apps.googleusercontent.com',
  })

  return (
    <div>
      {!isSignedIn && (
        <div className="sign-in">
          <img src={iLabLogo} alt="iLab logo" className="sign-in__logo"/>
          <button onClick={signIn} className="sign-in__btn btn">
            Sign in
          </button>
        </div>
      )}
      

      {isSignedIn && (
        <Layout>
          <Hero visitorName={googleUser.profileObj.givenName} />
          <Value />
          <Balance />
          <HowHero />
          <CoCreationModel />
          <Stakeholders />
          <ProjectOverview />
          <OpenHouse />
        </Layout>
      )}
    </div>
  )
}

export default App