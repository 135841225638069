import React from "react"

import "./co-creation-model.scss"

import CoCreationModelModel from "../../assets/images/co-creation-model.png"


const CoCreationModel = () => (
  <div className="co-creation-model">
    <div className="co-creation-model__bg"></div>
    <div className="co-creation-model__title">
      <p className="co-creation-model__title__label label">
        co-creation
      </p>
      <h1>
        A customer centric mindset and Design thinking procceses are the building blocks in everything we do.
      </h1>
    </div>

    <div className="co-creation-model__model">
        <img src={CoCreationModelModel} alt="Co-Creation Model"/>
    </div>

    {/* <div className="co-creation-model__download">
        <p>
            Read our promise to customers when we start a new co-creation projects together…
        </p>
        <a href="https://drive.google.com/file/d/1aXCFYE7QgdpN4pxxo919Y9yfQVBXFrHm/view?usp=sharing" className="btn" target="_blank">Download pdf</a>
    </div> */}
  </div>
)

export default CoCreationModel