import React from "react"

import "./stakeholders.scss"

import arrow from "../../assets/icons/arrow.svg"


const Stakeholders = () => (
  <div className="stakeholders">
    <div className="stakeholders__bg"></div>
    <div className="stakeholders__title">
      <p className="stakeholders__title__label label">
        co-creation project
      </p>
      <h1>
        Many type of stakeholders are usually involved
      </h1>
    </div>

    <div className="stakeholders__graph">
        <h2 className="stakeholders__graph__side-headers">
            Roche Stakeholders
        </h2>
        
        <ul className="stakeholders__graph__values stakeholders__graph__values--users">
            <li>Patient Journey partners</li>
            <li>HSP</li>
            <li>Squad Leads</li>
            <li>
                Innovation Lab
                <ul>
                    <li>UX Strategist</li>
                    <li>UX Designer</li>
                    <li>Project Manager</li>
                </ul>
            </li>
            <li>LTO</li>
        </ul>

        <div className="stakeholders__graph__center">
            <h2>New solutions beyond-the-pill</h2>
            <p>that equip patients and HCPs to understand the disease better and take the right actions faster</p>
        </div>

        <ul className="stakeholders__graph__values stakeholders__graph__values--external">
            <li>Regions</li>
            <li>Hospital Management teams</li>
            <li>HCPs</li>
            <li>Start-ups</li>
            <li>Universities</li>
        </ul>

        <h2 className="stakeholders__graph__side-headers">
            External Stakeholders
        </h2>
    </div>

    <img src={arrow} alt="arrow" className="bottom-arrow"/>

  </div>
)

export default Stakeholders