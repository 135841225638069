import React from "react"

import "./balance.scss"

import scale from "../../assets/video/weight-balance.webm"
import scaleGif from "../../assets/images/scale2.svg"
import arrow from "../../assets/icons/arrow-blue.svg"


const Balance = () => (
  <div className="balance">
    <div className="balance__bg"></div>
    <div className="balance__title">
      <p className="balance__title__label label">
        Balanced Focus
      </p>
      <h1>
        We need to evolve our customer approach
      </h1>
    </div>

    <div className="balance__content">
        <div className="balance__content__info">
            <div className="balance__content__info__content">
                <h2>Old customer approach</h2>
                <p>Product info in center of customer interactions</p>
            </div>

            <div className="balance__content__info__content">
                <h2>New customer approach</h2>
                <p>Balanced focus on solving customer needs and have a dialogue around our products</p>
            </div>
        </div>

        {/* <div className="balance__content__info">
            <h3>Product info</h3>
            <h3>Customer needs</h3>
        </div> */}
        <video autoPlay muted className="balance__content__scale__desktop balance__content__scale">
          <source src={scale} type="video/webm"/>
        </video>
        <img src={scaleGif} alt="scale" className="balance__content__scale__mobile"/>

    </div>

    <img src={arrow} alt="arrow" className="bottom-arrow"/>

  </div>
)

export default Balance