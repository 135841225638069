import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import _ from 'lodash'
import Img from 'gatsby-image'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'

import "./projects.scss"

import arrow from "../../assets/icons/arrow-blue.svg"
import CloseBtn from "../../assets/icons/cross.svg"
import playBtn from "../../assets/icons/play-btn.svg"


const ProjectOverview = ({ projects }) => {

    const data = useStaticQuery(graphql`
        query {
            contentfulProjectOverview {
                name
                id
                projects {
                    projectName
                    id
                    customerWin {
                        json
                    }
                    projectPartners {
                        json
                    }
                    rocheWin {
                        json
                    }
                    theProblem {
                        json
                    }
                    theSolution {
                        json
                    }
                    video
                    videoLink
                    projectImage {
                        fluid(maxWidth: 900) {
                          ...GatsbyContentfulFluid
                        } 
                    }
                }
            }
        }
    `)


    function openModal (e) {
        const [modal] = e.currentTarget.parentElement.getElementsByClassName('project__modal-wrapper');

        modal.classList.add('active');
        document.documentElement.style.overflow = 'hidden';
    }

    function closeModal (e) {
        let modal = e.currentTarget.parentElement;

        if (!modal.classList.contains('project__modal-wrapper')) {
            modal = e.currentTarget.parentElement.parentElement;
        }

        modal.classList.remove('active');
        document.documentElement.style.overflow = 'auto';
    }

    const renderProject = (project) => (
        <div key={project.id} className="project">
            <div className="project__item" onClick={openModal}>
                <div className="project__item__image">
                    <Img fluid={project.projectImage.fluid} alt="cases-image" />
                </div>
                <p className="project__item__label">CASE</p>
                <h3 className="project__item__name">{project.projectName}</h3>
                <img src={arrow} alt="arrow" className="project__item__arrow"/>
            </div>

            <div className="project__modal-wrapper">
                <div className="close-btn" onClick={closeModal}><img src={CloseBtn} alt="close-button"/></div>
                <div className="project__modal-contentbox">
                    <div className="content content__image">
                        { project.video && (<img src={playBtn} alt="arrow" className="content__image__play"/>)}
                        <a href={project.videoLink} target="_blank" rel="noreferrer"><Img fluid={project.projectImage.fluid} alt="cases-image" /></a>
                    </div>

                    <div className="content content__info">
                        <p className="content__info__label">CASE</p>
                        <h3 className="content__info__headline">{project.projectName}</h3>

                        <h4 className="content__info__smaller-headers">The Problem</h4>
                        <div className="content__info__desc">
                            {documentToReactComponents(project.theProblem.json)}
                        </div>

                        <h4 className="content__info__smaller-headers">The Solution</h4>
                        <div className="content__info__desc">
                            {documentToReactComponents(project.theSolution.json)}
                        </div>

                        <div className="content__info__wins">
                            <div className="content__info__wins__customer">
                                <h4 className="content__info__smaller-headers">Customer Win</h4>
                                <div className="content__info__desc">
                                    {documentToReactComponents(project.customerWin.json)}
                                </div>
                            </div>

                            <div className="content__info__wins__roche">
                                <h4 className="content__info__smaller-headers">Roche Win</h4>
                                <div className="content__info__desc">
                                    {documentToReactComponents(project.rocheWin.json)}
                                </div>
                            </div>

                            <div className="content__info__wins__partners">
                                <h4 className="content__info__smaller-headers">Project Partners</h4>
                                <div className="content__info__desc">
                                    {documentToReactComponents(project.projectPartners.json)}
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    )
    

    return (
        <div className="projects">
            <div className="projects__bg"></div>
            <div className="projects__title">
            <p className="projects__title__label label">
                Cases
            </p>
            <h1>
                Cases
            </h1>
            </div>

            <div className="projects__project-overview">
                <div className="container">

                    {_.map(data.contentfulProjectOverview.projects, renderProject)}
                        
                </div>

            </div>
            
        </div>

        
    )
}


export default ProjectOverview