import React from "react"

import "./how-hero.scss"

import arrow from "../../assets/icons/arrow.svg"
import drawnArrow from "../../assets/icons/drawn-arrow.svg"
import resultStrokes from "../../assets/icons/result-strokes.svg"

const HowHero = () => (
  <div className="how-hero">
    <div className="how-hero__bg"></div>
    <div className="how-hero__title">
      <p className="how-hero__title__label label">
        co-creation
      </p>
      {/* <h1>
        The Process
      </h1> */}
    </div>

    <div className="how-hero__flow">
      <div className="how-hero__flow__step how-hero__flow__step-one">
        <h5>How do I capture customer needs?</h5>
        <img src={drawnArrow} alt="Drawn arrow" className="how-hero__flow__step-one__arrow-right" />
        <a href="https://drive.google.com/file/d/1b9NyMDLTE4oocDFj6OfF6AcKI-ug3MRo/view?usp=sharing" className="btn" target="_blank">Need capture framework</a>
        <img src={drawnArrow} alt="Drawn arrow" className="how-hero__flow__step-one__arrow-left" />
      </div>

      <div className="how-hero__flow__step how-hero__flow__step-two">
        <h5>I have captured a need! How do I validate it?</h5>
        <img src={drawnArrow} alt="Drawn arrow" className="how-hero__flow__step-two__arrow-right" />
        <a href="https://drive.google.com/file/d/16E-gVJSIZLZSVfYTPXwW5cM01ciihQxA/view?usp=sharing" className="btn" target="_blank">Validation funnel</a>
        <img src={drawnArrow} alt="Drawn arrow" className="how-hero__flow__step-two__arrow-left" />
      </div>

      <div className="how-hero__flow__step how-hero__flow__step-three">
        <h5>It’s been validated! But we need a MVP?</h5>
        <img src={drawnArrow} alt="Drawn arrow" className="how-hero__flow__step-three__arrow-right" />
        <a href="https://drive.google.com/file/d/1aXCFYE7QgdpN4pxxo919Y9yfQVBXFrHm/view?usp=sharing" className="btn" target="_blank">coming soon!</a>
        <img src={drawnArrow} alt="Drawn arrow" className="how-hero__flow__step-three__arrow-left" />
      </div>

      <div className="how-hero__flow__step how-hero__flow__step-four">
        <h5>I have POC! What’s next?</h5>
        <img src={drawnArrow} alt="Drawn arrow" className="how-hero__flow__step-four__arrow-right" />
        <a href="https://drive.google.com/file/d/1kcoVIIO4GFdsW5cviMSNQMBv9G3CW5a1/view?usp=sharing" className="btn" target="_blank">implement & scale</a>
        <img src={resultStrokes} alt="Drawn arrow" className="how-hero__flow__step-four__result-strokes" />
      </div>
    </div>

    <img src={arrow} alt="arrow" className="bottom-arrow"/>

  </div>
)

export default HowHero